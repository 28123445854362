import React from "react";

export const Success = () => {

  return (
    <div>
          <div>
            <h1 className="center-align" style={{fontSize: "50px", color: "grey"}}>Дякуємо!</h1>
            <p className="center-align" style={{fontSize: "20px", color: "grey"}}>Очікуйте на інформацію найбижчим часом!</p>
          </div>
    </div>
  );
};
