import React, { useContext, useEffect } from "react";
import { useHttp } from "../../hooks/http.hook";
import { Loader } from "../../components/Loader";
import { GlobalContext } from "../../context/GlobalContext";
import { withRouter } from "react-router-dom";
import { StepButtons } from "../../components/StepButtons";
import { useWindowSize } from "../../hooks/windowsize.hook";

const Step2 = () => {
  const size = useWindowSize();
  const { loading } = useHttp();
  const globalCont = useContext(GlobalContext);

  useEffect(() => {
    window.M.updateTextFields();
  });

  useEffect(() => {
    globalCont.setStep(1);
  }, [globalCont]);

  const yearChangeHandler = (event) => {
    console.log(event.target.value);
    globalCont.setApp({ ...globalCont.app, Year: event.target.value });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="step">
        <label style={{ fontSize: "20px" }}>Вкажіть рік випуску:</label>
        <div className="row double">
          <div className="col s12 m12 l12 year-col">
            <p className="year-box-double hoverable">
              <label>
                <input
                  onChange={yearChangeHandler}
                  name={"year"}
                  type="radio"
                  value="до 2002"
                  className={globalCont.app.Year == "до 2002" ? ":checked" : ""}
                  // checked={globalCont.app.Year === "до 2002" ? true : false}
                  /* defaultChecked={globalCont.app.year - 1} */
                />
                <span>до 2002</span>
              </label>
            </p>
          </div>
        </div>
        {size.width > 600 ? (
          <div className="row">
            <div className="col s12 m6 l6 year-col">
              {globalCont.years.slice(0, 10).map((year, index) => {
                return (
                  <p key={index} className="year-box hoverable">
                    <label>
                      <input
                        onChange={yearChangeHandler}
                        name={"year"}
                        type="radio"
                        value={year}
                        checked={globalCont.app.Year == year ? true : false}
                        /* defaultChecked={globalCont.app.year - 1} */
                      />
                      <span>{year}</span>
                    </label>
                  </p>
                );
              })}
            </div>
            <div className="col s12 m6 l6 year-col">
              {globalCont.years.slice(10).map((year, index) => {
                return (
                  <p key={index} className="year-box hoverable">
                    <label>
                      <input
                        onChange={yearChangeHandler}
                        name={"year"}
                        type="radio"
                        value={year}
                        checked={globalCont.app.Year == year ? true : false}
                        // defaultChecked={globalCont.app.Year}
                      />
                      <span>{year}</span>
                    </label>
                  </p>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="row">
            {globalCont.years.map((year, index) => {
              return (
                <p key={index} className="year-box hoverable">
                  <label>
                    <input
                      onChange={yearChangeHandler}
                      name={"year"}
                      type="radio"
                      value={year}
                      checked={globalCont.app.Year == year ? true : false}
                      // defaultChecked={globalCont.app.Year}
                    />
                    <span>{year}</span>
                  </label>
                </p>
              );
            })}
          </div>
        )}
      </div>
      <div>
        <StepButtons
          nextButtonDisabled={globalCont.app.Year === "2000" ? true : false}
        />
      </div>
    </>
  );
};
export default withRouter(Step2);
