import React, { useContext, useEffect, useState } from "react";
import { useHttp } from "../../hooks/http.hook";
import { Loader } from "../../components/Loader";
import { GlobalContext } from "../../context/GlobalContext";
import { withRouter } from "react-router-dom";
import { StepButtons } from "../../components/StepButtons";

const Step3 = () => {
  const { loading } = useHttp();
  const globalCont = useContext(GlobalContext);

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {
    window.M.updateTextFields();
  }, []);

  useEffect(() => {
    globalCont.setStep(2);
  }, [globalCont]);

  const milageChangeHandler = (event) => {
    globalCont.setApp({ ...globalCont.app, Milage: event.target.value });
    setNextButtonDisabled(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="step">
        <label style={{ fontSize: "20px" }}>Який пробіг автомобіля? (км)</label>
        <div className="input-field">
          <input
            id="vehicleName"
            type="number"
            value={globalCont.app.Milage}
            onChange={milageChangeHandler}
            name="vehicleName"
          />
        </div>
        <p className="range-field" style={{ display: "flex", color: "grey" }}>
          0
          <input
            type="range"
            id="milage"
            min="0"
            max="500000"
            value={globalCont.app.Milage}
            onChange={milageChangeHandler}
          />
          500000
        </p>
      </div>
      <div>
        <StepButtons nextButtonDisabled={nextButtonDisabled} />
      </div>
    </>
  );
};

export default withRouter(Step3);
