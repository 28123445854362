import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useRoutes } from "./routes";
import "materialize-css";
import { GlobalContext } from "./context/GlobalContext";
import { useHttp } from "./hooks/http.hook";
import { useMessage } from "./hooks/message.hook";
import { CONSTS } from "./CONST";
// import GoogleAnalytics from "./components/GoogleAnalytics";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    // gtmId: 'GTM-KTT67BG',
    gtmId: 'GTM-T2977TD',
    dataLayer: {
      staticData: 'my-application',
      page: 'app'
  }
}
// const tagManagerArgs = {
//     gtmId: 'GTM-KTT67BG',
//     dataLayerName: 'PageDataLayer'
// }


function App() {
  const routes = useRoutes();
  
  
  const [step, setStep] = useState(0);
  const message = useMessage();
  const [app, setApp] = useState({
    DescriptionAuto: "",
    mobileNumber: "",
    Year: "2000",
    Milage: "150000",
    Sum: "250000",
    Source: "quiz",
  });
  const [isStartPage, setIsStartPage] = useState(true);

  const [progress, setProgress] = useState(0);
  
  const years = [];
  for (let index = 0; index < 20; index++) {
    years.push(new Date().getFullYear() - (19 - index));
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    return () => {
      // cleanup
    }
  })
  
  return (
    <GlobalContext.Provider
      value={{
        step,
        setStep,
        app,
        setApp,
        isStartPage,
        setIsStartPage,
        progress,
        setProgress,
        years
      }}
    >
      <Router>
        {/* {!isStartPage && <Navbar />} */}
        {isStartPage && <div>{routes}</div>}
        {!isStartPage && <div className="container">{routes}</div>}
      </Router>

    </GlobalContext.Provider>
  );
}

export default App;
