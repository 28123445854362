import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { StepButtons } from "../../components/StepButtons";
import bonus from "../../assets/bonus.png";
import { useWindowSize } from "../../hooks/windowsize.hook";
import { Card } from "../../components/Card";
import Step1 from "../../components/steps/Step1";
import TagManager from "react-gtm-module";

const Step1Page = () => {
  const globalCont = useContext(GlobalContext);
  const size = useWindowSize();

  const tagManagerArgs = {
    // gtmId: 'GTM-KTT67BG',
    gtmId: 'GTM-T2977TD',
    dataLayer: {
      staticData: 'my-application',
      page: 'step1'
    }
  };

  /* const tagManagerArgs = {
    'gtmId': "GTM-KTT67BG" ,
    dataLayer: {
        'js': new Date(), 
        event: 'PageViewEvent',
        page: 'step1',
        
    },
    dataLayerName: 'PageDataLayer' 
  } */

  useEffect(() => {
    // console.log("dsdasda");
    document.title = `step1`;
    TagManager.dataLayer(tagManagerArgs);
    return () => {
      // cleanup
    }
  })
  // TagManager.dataLayer(tagManagerArgs);

  useEffect(() => {
    globalCont.setStep(0);
    globalCont.setIsStartPage(false);
  }, [globalCont]);

  let stepComponent = <Step1 />;

  return (
    <>
      <div className="step1">
        <Card step={stepComponent}></Card>
      </div>
    </>
  );
};

export default withRouter(Step1Page);
