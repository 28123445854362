import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import ProgressBar from "@ramonak/react-progress-bar";

export const StepButtons = ({nextButtonDisabled}) => {
  const history = useHistory();
  const [localRoutes, setLocalRoutes] = useState([ 
    "step1",
    "step2",
    "step3",
    "step4",
  ]);
  
 
  const globalCont = useContext(GlobalContext);

  const navigate = (step) => {
    //console.log(step);
    console.log(step);
    history.push(`/${localRoutes[step]}`);
  }

  const nextStepHandler = () => {
    globalCont.setStep(globalCont.step + 1);
    globalCont.setProgress(globalCont.progress + 25)
    navigate(globalCont.step + 1);
  };

  const prevStepHandler = () => {
    globalCont.setStep(globalCont.step - 1);
    globalCont.setProgress(globalCont.progress - 25)
    navigate(globalCont.step - 1);
  };

  return (
    <>
    {/* <hr/> */}
    <ProgressBar completed={globalCont.progress} />
    <div
      style={{ display: "flex", justifyContent: "center", paddingTop: "5px" }}
    >
      {globalCont.step > 0 && (
        <button
          className="waves-effect waves-light btn btn-style"
          onClick={prevStepHandler}
        >
          <i className="material-icons left">arrow_back</i>
          Назад
        </button>
      )}
      {globalCont.step < 3 && !nextButtonDisabled && (
        <button 
          style={{ marginLeft: "5px" }}
          className="waves-effect waves-light btn btn-style"
          onClick={nextStepHandler}
        >
          <i className="material-icons left">arrow_forward</i>
          Далі
        </button>
      )}
      {globalCont.step < 3 && nextButtonDisabled && (
        <button 
          style={{ marginLeft: "5px" }}
          className="waves-effect waves-light btn"
          disabled
        >
          <i className="material-icons left">arrow_forward</i>
          Далі
        </button>
      )}
      {globalCont.step === 3 && nextButtonDisabled && (
        <button 
          style={{ marginLeft: "5px" }}
          className="waves-effect waves-light btn"
          onClick={()=>history.push(`/laststep`)}
          disabled
        >
          <i className="material-icons left">arrow_forward</i>
          Останній крок
        </button>
      )}
      {globalCont.step === 3 && !nextButtonDisabled &&(
        <button 
          style={{ marginLeft: "5px" }}
          className="waves-effect waves-light btn"
          onClick={()=>history.push(`/laststep`)}
        >
          <i className="material-icons left">arrow_forward</i>
          Останній крок
        </button>
      )}
    </div>
    </>
  );
};
