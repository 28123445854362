import React, { useContext, useEffect, useState } from "react";
import { PhoneInput } from "../../components/PhoneInput";
import { GlobalContext } from "../../context/GlobalContext";
import bonus from "../../assets/bonus.png";
import { useMessage } from "../../hooks/message.hook";
import { useHistory } from "react-router";
import { useHttp } from "../../hooks/http.hook";
import { Loader } from "../../components/Loader";
import { useWindowSize } from "../../hooks/windowsize.hook";

export const LastStep = () => {
  const globalCont = useContext(GlobalContext);
  const message = useMessage();
  const history = useHistory();
  const { request, loading, clearError, error } = useHttp();
  const size = useWindowSize();
  let politics =
    "https://avto.lombard1.com.ua/wp-content/uploads/2021/12/politica.pdf";
  const [consent, setConsent] = useState(true);

  useEffect(() => {
    window.M.updateTextFields();
  }, []);

  useEffect(() => {
  });

  const handleInput = ({ target: { value } }) =>
    globalCont.setApp({
      ...globalCont.app,
      mobileNumber: value,
    });

  const getCost = () => {
    let phoneFormatted = globalCont.app.mobileNumber.replace("+38", "");
    phoneFormatted = phoneFormatted.replace(/[-_()\s]/g, "");
    if (phoneFormatted.length !== 10) {
      message("Заповніть, будь-ласка, номер телефону");
      return;
    }
    globalCont.setApp({
      ...globalCont.app,
      mobileNumber: phoneFormatted,
    });
    saveHandler();
  };

  const saveHandler = async () => {
    try {
      const resp = await request(
        `https://lmob.pershyy.top/avtolombard1quiz-backend/bufferForCallbackFromStorm.php`,
        "POST",
        {
          ...globalCont.app,
        }
      );
      if (resp.Result === true) {
        message(resp.message);
        history.push(`/success`);
      }
    } catch (e) {}
  };

  useEffect(() => {
    message(error);
    clearError();
  }, [error, message, clearError]);

  const changeConsenthandler = () => {
    setConsent(!consent);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      {size.width > 600 ? (
        <div className="card z-depth-5" style={{ padding: "30px" }}>
          <div className="card-content center">
            <h3 className="bold">
              Отримайте розрахунок кредиту за номером телефону
            </h3>
          </div>
          <div
            className="horizontal z-depth-5 card"
            style={{
              padding: "2rem",
              minHeight: "570px!important",
              maxWidth: "1000px",
              margin: "50px auto 0 auto",
            }}
          >
            <div className="card-image">
              <img src={bonus} alt="111" className="bonus" />
            </div>
            <div className="card-stacked">
              <div className="card-content">
                <label htmlFor="link">Введіть номер телефону</label>
                <div className="input-field">
                  <PhoneInput
                    value={globalCont.app.mobileNumber}
                    onChange={handleInput}
                  ></PhoneInput>
                </div>
              </div>
              <div className="card-action">
                <label className="b-checkbox checkbox">
                  <input
                    type="checkbox"
                    checked={consent}
                    onChange={() => changeConsenthandler()}
                  ></input>
                  {/* <span className="check"></span> */}
                  <span className="control-label">
                    <span>
                      З{" "}
                      <a href={politics} target="_blank">
                        політикою конфіденційності
                      </a>
                      ознайомлений(а)
                    </span>
                  </span>
                </label>
                {}
                <button
                  onClick={getCost}
                  className="pulse waves-effect waves-light btn btn-large"
                  style={{
                    margin: "10px",
                    backgroundColor: "#ef1f31",
                    width: "93%",
                  }}
                  disabled={!consent}
                >
                  Отримати розрахунок
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="card z-depth-5"
          style={{
            padding: "2rem",
            minHeight: "570px!important",
            maxWidth: "1000px",
            margin: "50px auto 0 auto",
          }}
        >
          <div className="card-content center">
            <h5 className="bold">
              Отримайте розрахунок кредиту за номером телефону
            </h5>
          </div>
          <div className="card-image">
            <img src={bonus} alt="111" className="bonus" />
          </div>
          <div className="card-stacked">
            <div className="card-content" style={{padding: "0px"}}>
              <label htmlFor="link">Введіть номер телефону</label>
              <div className="input-field">
                <PhoneInput
                  value={globalCont.app.mobileNumber}
                  onChange={handleInput}
                ></PhoneInput>
              </div>
            </div>
            <div className="card-action" style={{padding: "0px"}}>
              <label className="b-checkbox checkbox">
                <input
                  type="checkbox"
                  checked={consent}
                  onChange={() => changeConsenthandler()}
                ></input>
                <span className="check"></span>
                <span className="control-label">
                  <span>
                    З{" "}
                    <a href={politics} target="_blank">
                      політикою конфіденційності
                    </a>
                    ознайомлений(а)
                  </span>
                </span>
              </label>
              <button
                onClick={getCost}
                className="pulse waves-effect waves-light btn btn-large"
                style={{
                  margin: "10px",
                  backgroundColor: "#ef1f31",
                  width: "93%",
                }}
                disabled={!consent}
              >
                Отримати розрахунок
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
