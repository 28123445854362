import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { withRouter } from "react-router-dom";
import { StepButtons } from "../../components/StepButtons";

const Step4 = () => {
  const globalCont = useContext(GlobalContext);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {
    window.M.updateTextFields();
  }, []);

  useEffect(() => {
    globalCont.setStep(3);
  }, [globalCont]);

  const changeHandler = (event) => {
    //console.log(event);
    globalCont.setApp({
      ...globalCont.app,
      [event.target.name]: event.target.value,
    });
    setNextButtonDisabled(false);
  };

  return (
    <>
      <div className="step">
        <label htmlFor="deviceName" style={{ fontSize: "20px" }}>
          На яку суму ви розраховуєте? (грн)
        </label>
        <div className="input-field">
          <input
            id="sum"
            type="number"
            value={globalCont.app.Sum}
            onChange={changeHandler}
            name="Sum"
          />
        </div>
        <p className="range-field" style={{ display: "flex", color: "grey"}}>
          10000
          <input
            type="range"
            id="sum"
            min="0"
            max="500000"
            name="Sum"
            value={globalCont.app.Sum}
            onChange={changeHandler}
          />
          500000
        </p>
      </div>
      <div>
        <StepButtons nextButtonDisabled={nextButtonDisabled} />
      </div>
    </>
  );
};

export default withRouter(Step4);
