import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { StepButtons } from "../../components/StepButtons";
import bonus from "../../assets/bonus.png";
import { useWindowSize } from "../../hooks/windowsize.hook";
import {Card} from "../../components/Card";
import Step2 from "../../components/steps/Step2";

const Step2Page = () => {
  const globalCont = useContext(GlobalContext);
  const size = useWindowSize();

  useEffect(() => {
    globalCont.setStep(1);
    globalCont.setIsStartPage(false);
  }, [globalCont]);

  let stepComponent = (<Step2 />)

  return (
    <>
      {
        <Card step={stepComponent}>
          
        </Card>
      }
    </>
  );
};

export default withRouter(Step2Page);
