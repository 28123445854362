import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
// import { useNavigate } from "react-router-dom";
import bonus from "../../assets/bonus.png";
import { Card } from "../../components/Card";
import { Success } from "../../components/steps/Success";
import { GlobalContext } from "../../context/GlobalContext";
import { useWindowSize } from "../../hooks/windowsize.hook";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "project",
    page: "success",
  },
  dataLayerName: "PageDataLayer",
};


export const SuccessPage = () => {
  const globalCont = useContext(GlobalContext);
  
  TagManager.dataLayer(tagManagerArgs)

  useEffect(() => {
    globalCont.setStep(0);
    globalCont.setIsStartPage(false);
  }, [globalCont]);

  let stepComponent = <Success />;

  return <>
  <div className='success'>
    {<Card step={stepComponent}></Card>}
  </div>
  </>;
};
