import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { StepButtons } from "../../components/StepButtons";
import { useWindowSize } from "../../hooks/windowsize.hook";

const Step1 = () => {
  const globalCont = useContext(GlobalContext);
  const size = useWindowSize();

  useEffect(() => {
    window.M.updateTextFields();
  });

  useEffect(() => {
    globalCont.setStep(0);
    globalCont.setIsStartPage(false);
  }, [globalCont]);

  const changeHandler = (event) => {
    globalCont.setApp({
      ...globalCont.app,
      DescriptionAuto: event.target.value,
    });
    console.log(event.target.value.length);
  };

  return (
    <>
      <div className="step">
        <span className="card-title grey-text text-darken-4 bold">
          Вкажіть марку та модель авто
        </span>
        <div className="row">
          <div className="input-field">
            <input
              id="deviceName"
              type="text"
              value={globalCont.app.DescriptionAuto}
              onChange={changeHandler}
              name="deviceName"
            />
            <label htmlFor="deviceName">Марка та модель авто</label>
          </div>
        </div>
      </div>
      <div>
        <StepButtons
          nextButtonDisabled={
            globalCont.app.DescriptionAuto.length > 1 ? false : true
          }
        />
      </div>
    </>
  );
};

export default withRouter(Step1);
