import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Background from "../../assets/title.png";
import Logo from "../../assets/logo.png";
import zeroImg from "../../assets/zero.png";
import "./styles/StartPage.css";
import { GlobalContext } from "../../context/GlobalContext";
import bonus from "../../assets/bonus.png";
import { useWindowSize } from "../../hooks/windowsize.hook";
import TagManager from 'react-gtm-module'

export const StartPage = () => {
  // const history = useHistory();
  const size = useWindowSize();
  const globalCont = useContext(GlobalContext);

  if (globalCont.progress > 0) {
    document.location.reload();
  }

  useEffect(() => {
    // let params = new URL(document.location).searchParams;
    // localStorage.setItem("partnerId", params.get("partnerId"));
    //console.log(classes);
  }, []);

  return (
    <div>
      <div className="row main">
        {size.width > 479 ? (
          <div className="col s12 m7 l7 main__left">
            <div style={styles.image}></div>
          </div>
        ) : (
          <div className="col s12 m7 l7">
            <div style={styles.mobileImage}></div>
          </div>
        )}

        <div className="col s12 m5 l5 main__right">
          <div className="row">
            <div className="col s12">
              <div className="row">
                <div
                  className="col "
                  style={{ ...styles.title, display: "flex" }}
                >
                  <img src={Logo} alt="111" className="logo" />
                  {size.width > 320 ? (
                    <div className="" style={{ fontSize: "18px" }}>
                      Більше 300 відділень ломбардів по всій Україні
                    </div>
                  ) : (
                    <div className="" style={{ fontSize: "14px" }}>
                      Більше 300 відділень ломбардів по всій Україні
                    </div>
                  )}
                </div>
              </div>
              <div>
                <h1
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    margin: "5px",
                  }}
                >
                  Розрахуйте кредит під заставу Вашого авто
                </h1>
                <h1
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    margin: "5px",
                  }}
                >
                  (авто залишаеться у Вас)
                </h1>
                <h2 style={{ fontSize: "18px", margin: "5px" }}>
                  <p>
                    Пройдіть тест: яку суму кредиту ви отримаете, умови для
                    застави, адресу найближчого відділення
                  </p>
                  <p>
                    <strong>ТИСНІТЬ КНОПКУ👇</strong>
                  </p>
                </h2>

                {size.width > 479 ? (
                  <Link
                    className=" pulse waves-effect waves-light btn btn-large"
                    style={{ margin: "10px", backgroundColor: "#ef1f31" }}
                    to={{
                      pathname: `/step1/`,
                    }}
                  >
                    Створити
                  </Link>
                ) : (
                  <Link
                    className=" pulse waves-effect waves-light btn btn-large"
                    style={{
                      margin: "10px",
                      backgroundColor: "#ef1f31",
                      width: "93%",
                    }}
                    to={{
                      pathname: `/step1/`,
                    }}
                  >
                    Створити
                  </Link>
                )}
              </div>
            </div>
            <div className="col s12">
              {size.width > 702 ? (
                <div style={styles.footer}>
                  <div className="">ПТ «ЛОМБАРД «ПЕРШИЙ»</div>
                  <div className="">
                    Ліцензія від 28.11.2017 (Розпорядження НАЦКОМФІНПОСЛУГ
                    №4352)
                  </div>
                </div>
              ) : (
                <div>
                  <img
                    src={bonus}
                    alt="111"
                    style={{ width: "200px", background: "#ffffff" }}
                  />
                  <div style={styles.mobileFooter}>
                    <p style={styles.p1}>ПТ «ЛОМБАРД «ПЕРШИЙ»</p>
                  </div>
                  <div style={styles.mobileFooter}>
                    <p style={styles.p2}>
                      Ліцензія від 28.11.2017 (Розпорядження НАЦКОМФІНПОСЛУГ
                      №4352)
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  image: {
    //width: "100%",
    backgroundImage: `url(${Background})`,
    backgroundSize: "contain",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "0",
    bottom: "0",
    //background: "no-repeat 0 40%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100vh",
  },
  title: {
    margin: "10px",
    padding: "5px",
    borderBottom: "solid 1px var(--color-bg-2,rgba(0,0,0,.1))",
  },
  mobileImage: {
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "0",
    bottom: "0",
    //background: "no-repeat 0 40%",
    backgroundRepeat: "no-repeat",
    height: "200px",
  },
  footer: {
    display: "flex",
    alignContent: "space-between",
    flexDirection: "column",
    //height: "100vh",
    justifyContent: "flex-end",
    paddingBottom: "10px",
    position: "absolute",
    bottom: 0,
  },
  mobileFooter: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  p1: {
    margin: "0px",
  },
  p2: {
    margin: "0px",
    color: "grey",
    textAlign: "center"
  },
};
