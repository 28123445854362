import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { withRouter } from "react-router-dom";
import Step4 from "../../components/steps/Step4";
import { Card } from "../../components/Card";

const Step4Page = () => {
  const globalCont = useContext(GlobalContext);
  
  useEffect(() => {
    globalCont.setStep(3);
    globalCont.setIsStartPage(false);
  }, [globalCont]);

  let stepComponent = (<Step4 />)

  return (
    <>
      {
        <Card step={stepComponent}>
          
        </Card>
      }
    </>
  );
};


export default withRouter(Step4Page);
