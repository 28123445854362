import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { withRouter } from "react-router-dom";
import Step3 from "../../components/steps/Step3";
import { Card } from "../../components/Card";

const Step3Page = () => {
  const globalCont = useContext(GlobalContext);
  
  useEffect(() => {
    globalCont.setStep(2);
    globalCont.setIsStartPage(false);
  }, [globalCont]);

  let stepComponent = (<Step3 />)

  return (
    <>
      {
        <Card step={stepComponent}>
          
        </Card>
      }
    </>
  );
};

export default withRouter(Step3Page);