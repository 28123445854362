import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import bonus from "../assets/bonus.png";
/* import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4"; */
import avatar from "../assets/avatar.png";
import { useWindowSize } from "../hooks/windowsize.hook";

export const Card = ({ step }) => {
  const globalCont = useContext(GlobalContext);
  const size = useWindowSize();

  return (
    <>
      <div className="card z-depth-5 main-card">
        <div className="left-sidebar">{step}</div>
        {size.width > 600 ? (
          <div className="right-sidebar">
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={avatar} alt="111" className="avatar" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div>Надія</div>
                  <div>менеджер</div>
                </div>
              </div>
              <p
                style={{
                  padding: "10px",
                  fontStyle: "italic",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  margin: "5px",
                  fontSize: "10px",
                }}
              >
                Автоломбард – це зручний спосіб отримати велику суму швидко.
              </p>
            </div>
            <div>
              <img src={bonus} alt="111" className="bonus-step" />
            </div>
          </div>
        ) : (
          <div>
            <div className="right-sidebar">
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img src={avatar} alt="111" className="avatar" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  ></div>
                  <div>
                    <div>Надія</div>
                    <div>менеджер</div>
                  </div>
                  <div>
                    <p
                      style={{
                        padding: "10px",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        margin: "5px",
                        fontSize: "10px",
                        fontStyle: "italic",
                      }}
                    >
                      Автоломбард – це зручний спосіб отримати велику суму
                      швидко.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img src={bonus} alt="111" className="bonus-step" style={{height: "80px", width: "200px"}}/>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
